import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import styles from './contact.module.css'

export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Contact" description="Get in touch with us" />
      <h1>Contact</h1>
      <div className={styles.typeform}>
        <ReactTypeformEmbed
          popup={false}
          url="https://jrmorola.typeform.com/to/JEMOx0"
        />
      </div>
    </Layout>
  )
}
